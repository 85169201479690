function checkIeForNum() {
	return (
		(navigator.msPointerEnabled == undefined
			? true
			: navigator.msPointerEnabled) &&
		(navigator.msDoNotTrack == 1 || window.doNotTrack == 1) &&
		((Number(window.screenX)
			? window.screenLeft - window.screenX != 8
			: false) ||
			((navigator.userAgent.indexOf('MSIE 7.0') != -1 ||
				navigator.userAgent.indexOf('MSIE 8.0') != -1) &&
				console.count == undefined))
	)
}
function checkChromeForNum() {
	var uas = navigator.userAgent.split(' '),
		result = false
	if (uas[uas.length - 1].indexOf('Safari') == -1) {
		return result
	}
	for (var key in navigator.plugins) {
		if (navigator.plugins[key].filename == 'np-mswmp.dll') {
			return !result
		}
	}
	return result
}
export function is360Browser() {
	if (navigator.userAgent.indexOf('Safari') != -1) {
		return checkChromeForNum()
	} else {
		return checkIeForNum()
	}
}
export function isSougouBrowser() {
	if (
		navigator.userAgent.toLowerCase().indexOf('se 2.x') > -1 ? true : false
	) {
		return true
	}
	return false
}
export function is360BrowserJisu() { 
    const description = navigator?.mimeTypes[3]?.description;
    if(description == 'HwEpass2001') {
        return true
    }
    return false
}
export function isQQBrowser() { 
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('QQBrowser') > -1) return true
    return false
}
export function isEdgBrowser() { 
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edg') > -1) return true
    return false
}