import '../styles/Customer.scss'
import { Tag } from '@bytedance/mona-ui'
function Customer() {
    const usercode = 'https://wtplug.oss-cn-hangzhou.aliyuncs.com/webimages/code/WechatIMG6.jpg'
    return (
        <div className="Customerbox">
            <div className="usercodeimage">
               <img src={usercode} alt="" className="usercode" style={{borderRadius:"25px",width:'500rpx'}}/>
            </div>
            <div className="boconter">
                <div className="conter">
                    低调 CEO 的微信
                </div>
                <div className="shengfeng">
                    <Tag  bordered  type="default">使用咨询</Tag>
                    <Tag bordered  type="default">合作咨询</Tag>
                </div>
            </div>
        </div>
    )
}
export default Customer