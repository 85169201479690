import "./styles/App.scss";
import Home from "./pages/home";
import "@bytedance/mona-ui/dist/auxo.min.css";
// import "./pages/home/index.html";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Routes>
          <Route path="" element={<Home />} />
          {/* <Route path="404" element={<W />} /> */}

          {/* <Route path="/bar" element={<Bar />} /> */}
        </Routes>
      </BrowserRouter>
    </div>

    // <div className="App">
    // <Router>
    //     <Routes>
    //         <Route path="/" exact component={<Home/>} />
    //     </Routes>
    // </Router>
    // </div>
  );
}

export default App;
